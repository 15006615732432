import { animated, config, useSpring } from '@react-spring/web'
import clsx from 'clsx'
import DOMPurify from 'dompurify'
import { useCallback, useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSwitch from 'react-switch'

import data from './config'
import {
  CookieCategory,
  CookieCategoryType,
  CookieDetail,
} from '../../@types/cookies'
import Button from '../reusableComponents/Button'
import { CookieContext } from './CookieProvider'

function CookieModal() {
  const cookieContext = useContext(CookieContext)
  const { t, i18n } = useTranslation('global', { keyPrefix: 'cookies' })

  const [categoryOpen, setCategoryOpen] = useState<string>('')
  const [providerOpen, setProviderOpen] = useState<string>('')
  const [consent, setConsent] = useState<string[]>([])

  const styles = useSpring({
    from: {
      opacity: 0,
      marginTop: 40,
    },
    to: {
      opacity: 1,
      marginTop: 0,
    },
    config: { ...config.default, duration: 100 },
  })

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        cookieContext?.acknowledgeCookies()
        cookieContext?.showModal(false)
      }
    },
    [cookieContext]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  const toggleCategory = (id: string) => {
    setCategoryOpen(categoryOpen === id ? '' : id)
  }

  const toggleProvider = (id: string) => {
    setProviderOpen(providerOpen === id ? '' : id)
  }

  const handleConsent = (id: string, checked: boolean) => {
    const i = consent.indexOf(id)
    if (i === -1 && checked) setConsent([...consent, id])
    else if (!checked)
      setConsent([...consent.slice(0, i), ...consent.slice(i + 1)])
  }

  if (
    !data ||
    !i18n ||
    !i18n.resolvedLanguage ||
    !cookieContext ||
    !cookieContext.modalShown
  )
    return null

  const lang = i18n.resolvedLanguage || 'en'
  const categories = data.categories.map((category: CookieCategory) => {
    const tpe = data.categoryTypes.find(
      (tp: CookieCategoryType) => tp.id === category.categoryTypeFk.id
    )
    if (!tpe.active) return null

    const count = category.providers.reduce((p, c) => p + c.details.length, 0)
    return tpe && tpe[lang] ? (
      <div key={tpe.id}>
        <ReactSwitch
          onChange={(checked) =>
            handleConsent(category.categoryTypeFk.id, checked)
          }
          checked={
            tpe.necessary || consent.includes(category.categoryTypeFk.id)
          }
          disabled={tpe.necessary}
          checkedIcon={false}
          uncheckedIcon={false}
          className="float-right"
          onColor="#C58511"
        />

        <h3 className="mt-4 text-xl">
          {tpe[lang].name}
          <span className="ml-2 rounded-full bg-white-darker px-2 py-1 text-sm text-black">
            {count}
          </span>
        </h3>

        <button
          type="button"
          aria-label={t('showDetails')}
          onClick={() => toggleCategory(tpe.id)}
          className="mt-5 cursor-pointer text-left text-dark transition-all hover:text-bronze"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(tpe[lang].description),
          }}
        />
        <div
          className={clsx(
            'mt-4 max-h-0 overflow-hidden border border-dark pb-4 opacity-0 transition-all',
            {
              'max-h-[10000px] opacity-100': categoryOpen.includes(tpe.id),
            }
          )}
        >
          {category.providers.map((provider) => (
            <div key={`provider-${provider.id}`} className="px-4 py-2">
              <button
                type="button"
                className="my-4 block text-xl text-dark hover:text-bronze"
                onClick={() => toggleProvider(provider.id)}
              >
                {provider[lang].name}
                <span className="ml-2 rounded-full bg-white-darker px-2 py-1 text-sm text-black">
                  {provider.details.length}
                </span>
              </button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={provider[lang].link}
                className="btn-hover pb-1"
              >
                <span>{t('moreOnProvider')}</span>
              </a>
              <div
                className={clsx(
                  'max-h-0 overflow-hidden opacity-0 transition-all',
                  {
                    'max-h-[1000px] opacity-100': providerOpen.includes(
                      provider.id
                    ),
                  }
                )}
              >
                <div className="mt-6">
                  {provider.details.map((cookie: CookieDetail) => {
                    return (
                      <div
                        key={`cookie-${cookie.id}`}
                        className="border-1 bg-light mb-4 border px-4 py-4 normal-case"
                      >
                        <h5 className="mb-2">{cookie[lang].name}</h5>
                        <div
                          className="border-1 border-b"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              cookie[lang].description
                            ),
                          }}
                        />
                        <div className="flex pt-4">
                          <span className="w-1/2">
                            {t('validity')}: {cookie[lang].validity}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : null
  })

  return (
    <div
      className="align-center fixed left-0 top-0 z-10 flex h-screen w-full flex-col overflow-auto sm:justify-center"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
    >
      <div />

      <animated.div
        style={styles}
        className="z-20 mx-auto min-w-[75%] max-w-[900px] overflow-auto bg-white px-6 py-4"
      >
        <div className="border-gray border-b py-4 text-xl sm:text-2xl">
          {t('title')}
        </div>

        <div
          className="py-4"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data[i18n.resolvedLanguage].about),
          }}
        />

        {categories}

        <div className="flex flex-col lg:flex-row">
          <div className="text-center">
            <Button
              onClick={() => {
                cookieContext?.acknowledgeCookies(
                  data.categoryTypes
                    .filter((cT: CookieCategoryType) => cT.active)
                    .map((cT: CookieCategoryType) => cT.id)
                )
                cookieContext?.showModal(false)
              }}
              classNames="mr-4"
              text={t('allowAll')}
            />
          </div>

          <div className="text-center">
            <Button
              onClick={() => {
                cookieContext?.acknowledgeCookies()
                cookieContext?.showModal(false)
              }}
              text={t('disallowAll')}
            />
          </div>

          <div className="lg:align-right text-center lg:grow">
            <Button
              classNames="lg:float-right"
              onClick={() => {
                cookieContext?.acknowledgeCookies(consent)
                cookieContext?.showModal(false)
              }}
              text={t('setup')}
            />
          </div>
        </div>
      </animated.div>
    </div>
  )
}

export default CookieModal
