import { useMemo } from 'react'
import { TSupportedLanguages } from '../i18next'
import _countries from '../assets/json/countries.json'

export const GRANT_TOKEN = 'grant'
export const AUTH_TOKEN = 'auth_token'
export const REFRESH_TOKEN = 'refresh_token'
export const REMEMBER_ME = 'remember_me'

export const USE_SELLER_MOCK = true

export const SELLER_GROUP = 5 as const

export const CUSTOMER_GROUP = 4 as const

export const DEFAULT_COUNTRY = 16

export const useCountries = (lng: TSupportedLanguages) =>
  useMemo(() => {
    const result = _countries.map((c) => {
      return { value: c.id, name: c.name[lng] }
    })
    result.sort((a, b) => a.name.localeCompare(b.name))
    return result
  }, [lng])
