const PersonIcon = () => (
  <svg
    className="inline-block"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.97631 10.3171C3.60143 9.69201 4.44928 9.34082 5.33333 9.34082H10.6667C11.5507 9.34082 12.3986 9.69201 13.0237 10.3171C13.6488 10.9423 14 11.7901 14 12.6742V14.0075C14 14.3757 13.7015 14.6742 13.3333 14.6742C12.9651 14.6742 12.6667 14.3757 12.6667 14.0075V12.6742C12.6667 12.1437 12.456 11.635 12.0809 11.2599C11.7058 10.8849 11.1971 10.6742 10.6667 10.6742H5.33333C4.8029 10.6742 4.29419 10.8849 3.91912 11.2599C3.54405 11.635 3.33333 12.1437 3.33333 12.6742V14.0075C3.33333 14.3757 3.03486 14.6742 2.66667 14.6742C2.29848 14.6742 2 14.3757 2 14.0075V12.6742C2 11.7901 2.35119 10.9423 2.97631 10.3171Z"
      fill="#C58511"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00033 2.67415C6.89576 2.67415 6.00033 3.56958 6.00033 4.67415C6.00033 5.77872 6.89576 6.67415 8.00033 6.67415C9.1049 6.67415 10.0003 5.77872 10.0003 4.67415C10.0003 3.56958 9.1049 2.67415 8.00033 2.67415ZM4.66699 4.67415C4.66699 2.8332 6.15938 1.34082 8.00033 1.34082C9.84128 1.34082 11.3337 2.8332 11.3337 4.67415C11.3337 6.5151 9.84128 8.00749 8.00033 8.00749C6.15938 8.00749 4.66699 6.5151 4.66699 4.67415Z"
      fill="#C58511"
    />
  </svg>
)

export default PersonIcon
