import { FC, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import i18next from 'i18next'
import RoutesManager from './components/RoutesManager'
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import SellerActivationModals from './components/modals/SellerActivationModals'
import { CookieProvider } from './components/cookies/CookieProvider'
import CookieModal from './components/cookies/CookieModal'
import CookieBar from './components/cookies/CookieBar'
import Seo from './components/reusableComponents/Seo'
// import Loader from './components/reusableComponents/Loader'

// App without react-router, useful for testing
const App: FC = () => {
  return (
    <>
      <Header />
      <div className="min-h-[80vh]">
        <CookieProvider>
          <RoutesManager />
          <CookieBar />
          <CookieModal />
        </CookieProvider>
        <SellerActivationModals />
      </div>
      <Footer />
    </>
  )
}

export const WrappedApp = () => {
  useEffect(() => {
    if (i18next.resolvedLanguage) {
      const pt = window.location.pathname.match(/\/([a-z]{2})/)
      if (!pt || pt.length < 2 || pt[1] !== i18next.resolvedLanguage) {
        window.location.href = `${window.location.origin}/${i18next.resolvedLanguage}`
      } else document.documentElement.lang = i18next.resolvedLanguage
    }

    if (!document.getElementById('gtm-tag')) {
      setTimeout(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        })
        const j = document.createElement('script')
        j.id = 'gtm-tag'
        j.async = true
        j.defer = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-5QB4G4GQ'
        document.getElementsByTagName('head')[0].append(j)
      }, 300)
    }
  }, [])

  return (
    <BrowserRouter basename={`${i18next.resolvedLanguage}`}>
      <HelmetProvider>
        <Seo
          title={i18next.t('seo:home.title')}
          description={i18next.t('seo:home.desc')}
        />
        <ScrollToTop />
        <App />
      </HelmetProvider>
    </BrowserRouter>
  )
}

export default App
