import urlSlug from 'url-slug'
import NumisCategories from '../assets/json/numis_categories.json'
import arrayToTree from './arrayToTree'
import { TCart } from '../@types/cart'
import { TBaseOrderDetail } from '../@types/backend/orderDetail'
import { TReview, TReviewDetail } from '../@types/reviews'
import i18next from '../i18next'

type TMenuTreeJson = {
  id: number
  id_parent: number
  name_sk: string
  name_cs: string
  name_en: string
  name_de: string
  name_hu: string
}

export type TMenuRowWithChildren = TMenuTreeJson & {
  children?: TMenuTreeJson[]
}

interface OutputItem {
  value: string
  label: string
  children?: OutputItem[]
}

export const generateMenuTree = () => {
  const menuTree = arrayToTree<TMenuTreeJson>(NumisCategories)
  const mainPageCategory = menuTree[0].children as TMenuRowWithChildren[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return mainPageCategory[0].children as any[] // NUMISMATIKA, BANKOVKY, VYZNAMENÁNÍ

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const firstRow: any[] = []
  // if (isArray(mainPageCategory)) {
  //   mainPageCategory[0].children
  //     ?.map((ch) => ch.children)
  //     .forEach((parentCategory) => {
  //       parentCategory?.forEach((fr) => firstRow.push(fr))
  //     })
  // }

  // return firstRow
}

export const pickCategoryName = (
  menuItem:
    | TMenuRowWithChildren
    | TMenuTreeJson
    | (typeof NumisCategories)[number]
    | undefined,
  useWBR = true
) => {
  if (!menuItem) return ''
  let name = ''
  switch (i18next.resolvedLanguage) {
    case 'cs':
      name = menuItem.name_cs
      break
    case 'sk':
      name = menuItem.name_sk
      break
    case 'de':
      name = menuItem.name_de
      break
    case 'en':
      name = menuItem.name_en
      break
    case 'hu':
      name = menuItem.name_hu
      break
    default:
  }
  return useWBR ? name.replaceAll('/', '<wbr>/') : name
}

export const constructCategoryUrl = (
  menuItem:
    | TMenuRowWithChildren
    | TMenuTreeJson
    | (typeof NumisCategories)[number]
    | undefined
) => {
  return menuItem
    ? `/${i18next.t('urls.category')}/${menuItem.id}-${urlSlug(
        pickCategoryName(menuItem)
      )}`
    : `/${i18next.t('urls.category')}/2-${i18next.t('urls.latest')}`
}

export const generateCategoryTreeForProductEdit = (): OutputItem[] => {
  const result: OutputItem[] = []

  const idToOutputItem: { [id: number]: OutputItem } = {}

  NumisCategories.forEach((item) => {
    const outputItem: OutputItem = {
      value: item.id.toString(),
      label: pickCategoryName(item, false),
    }
    idToOutputItem[item.id] = outputItem

    if (item.id_parent === 2) {
      result.push(outputItem)
    } else {
      const parentItem = idToOutputItem[item.id_parent]
      if (parentItem) {
        parentItem.children = parentItem.children || []
        parentItem.children.push(outputItem)
      }
    }
  })

  return result
}

export const calculateTotalCartPrices = (data: TCart | undefined) => {
  const productsPrice =
    data?.items.reduce(
      (p, c) => p + c.products.reduce((pp, cc) => pp + cc.price, 0),
      0
    ) || 0
  const shippingPrice =
    data?.items.reduce((p, c) => {
      const products = c.products.reduce((pp, cc) => pp + cc.price, 0)
      const shipping =
        products >= Number(c.freeShipping) ? 0 : Number(c.deliveryPrice)
      return p + shipping
    }, 0) || 0
  const totalPrice = productsPrice + shippingPrice

  return { productsPrice, shippingPrice, totalPrice }
}

export const calculateTotalOrderPrices = (
  data: TBaseOrderDetail | undefined
) => {
  const productsPrice =
    data?.sellers.reduce(
      (p, c) => p + c.products.reduce((pp, cc) => pp + Number(cc.price), 0),
      0
    ) || 0
  const shippingPrice =
    data?.sellers.reduce((p, c) => p + Number(c.deliveryPrice), 0) || 0
  const totalPrice = productsPrice + shippingPrice
  return { productsPrice, shippingPrice, totalPrice }
}

export type TFile2Base64 = {
  file: string
  name: string
  suffix: string
  type: string
}

export const file2Base64 = (file: File): Promise<TFile2Base64> => {
  return new Promise<TFile2Base64>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () =>
      resolve({
        file: reader.result?.toString() || '',
        name: file.name.split('.')[0],
        suffix: file.name.split('.')[1],
        type: file.type,
      })
    reader.onerror = (error) => reject(error)
  })
}

export const getBase64files = (files: File[]) => {
  return Promise.all(files.map(file2Base64))
}

export const calculateTotalRating = (review: TReview | TReviewDetail) => {
  let totalRating
  if (review.rating2 && review.rating3) {
    totalRating = (review.rating1 + review.rating2 + review.rating3) / 3
  } else {
    totalRating = review.rating1
  }
  return Number(totalRating.toFixed(2))
}

export const localizedRecord = <T>(value: T | ((locale: string) => T)) => {
  return Object.fromEntries(
    [i18next.resolvedLanguage].map((locale) => [locale, value])
  )
}
