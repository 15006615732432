import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import useCartQuery from '../api/query/useCartQuery'
import { calculateTotalCartPrices } from './helperFunctions'

export const useCartData = () => {
  const { isLoading, isError, data } = useCartQuery()

  return {
    prices: useMemo(() => calculateTotalCartPrices(data), [data]),
    isLoading,
    isError,
    data,
  }
}

export const useDesktopMenu = () => {
  return useMediaQuery({ query: '(min-width: 1024px)' })
}
