import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { api } from '../index'
import { TCart } from '../../@types/cart'

const useCartQuery = () => {
  const { i18n } = useTranslation()

  return useQuery({
    queryKey: ['cart'],
    queryFn: (): Promise<TCart> => {
      return api().get(`cart/${i18n.resolvedLanguage}`).json<TCart>()
    },
  })
}

export default useCartQuery
