import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { toast } from 'react-toastify'
import { CUSTOMER_GROUP, SELLER_GROUP } from '../utils/constants'
import PersonIcon from './dynamicIcons/PersonIcon'
import Button from './reusableComponents/Button'
import { TBECustomer, TBESeller } from '../@types/backend/user'
import useLogoutMutation from '../api/query/auth/useLogoutMutation'
import useBoundStore from '../slices/useBoundStore'

type TUserMenuProps = {
  me: TBECustomer | TBESeller
  closeMenu?: () => void
  isDesktop?: boolean
}

const UserMenu: FC<TUserMenuProps> = ({
  me,
  closeMenu = undefined,
  isDesktop = true,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { mutateAsync: doLogout } = useLogoutMutation()
  const { setSellerActivationModalOpened } = useBoundStore()

  const styles = isDesktop
    ? 'py-4 font-semibold'
    : 'py-2.5 font-medium text-[1.375rem]'

  const handleLogout = () => {
    doLogout()
      .then((data) => {
        if (data.status) {
          toast.success(t('user.nav.logout_successful'))
          navigate('/')
        }
      })
      .catch(() => {
        toast.error(t('common.error_occurred_somewhere'))
      })
  }

  return (
    <>
      {me.group === SELLER_GROUP ? (
        <span className="flex w-fit items-center rounded-full bg-white-darker px-2 py-1 font-semibold text-bronze">
          <PersonIcon />
          <span className="ml-2">{t('user.nav.seller_account')}</span>
        </span>
      ) : null}
      <h2>{me.nick}</h2>
      {me.group === CUSTOMER_GROUP && !me.seller_requested && (
        <Button
          text={t('user.nav.activate_seller_account')}
          classNames="!py-2 mt-2 hover:!brightness-90 w-full"
          variant="grey"
          onClick={() => {
            setSellerActivationModalOpened(true)
          }}
        />
      )}
      {me.group === CUSTOMER_GROUP && me.seller_requested && (
        <>
          <Button
            text={t('user.nav.waiting_for_seller_activation')}
            classNames="!py-2 mt-2 hover:!brightness-90 w-full"
            variant="grey"
            onClick={() => {
              setSellerActivationModalOpened(true)
            }}
          />
          <div className="mb-4 bg-white p-3 text-sm">
            {t('user.nav.waiting_for_seller_activation_info')}
          </div>
        </>
      )}
      {me.group === SELLER_GROUP ? (
        <NavLink
          className={`block border-b border-grey ${styles}`}
          to={`/${t('urls.account')}/${t('urls.user.my_products')}`}
          onClick={() => (closeMenu ? closeMenu() : null)}
        >
          {t('user.nav.my_products')}
        </NavLink>
      ) : null}
      <NavLink
        className={clsx('block border-b border-grey', styles, {
          active: pathname === `/${t('urls.account')}`,
          'border-t': me.group === CUSTOMER_GROUP,
        })}
        to={`/${t('urls.account')}/${t('urls.user.orders_history')}`}
        onClick={() => (closeMenu ? closeMenu() : null)}
      >
        {t('user.nav.orders_history')}
      </NavLink>
      {me.group === SELLER_GROUP ? (
        <>
          {/* <NavLink */}
          {/*  className={`block border-b border-grey ${styles}`} */}
          {/*  to={`/${t('urls.account')}/${t('urls.user.payments_overview')}`} */}
          {/*  onClick={() => (closeMenu ? closeMenu() : null)} */}
          {/* > */}
          {/*  {t('user.nav.payments_overview')} */}
          {/* </NavLink> */}
          <NavLink
            className={`block border-b border-grey ${styles}`}
            to={`/${t('urls.account')}/${t('urls.user.bidding')}`}
            onClick={() => (closeMenu ? closeMenu() : null)}
          >
            {t('user.nav.my_products_bidding')}
          </NavLink>
        </>
      ) : null}
      {me.group === CUSTOMER_GROUP ? (
        <NavLink
          className={`block border-b border-grey ${styles}`}
          to={`/${t('urls.account')}/${t('urls.user.user_settings')}`}
          onClick={() => (closeMenu ? closeMenu() : null)}
        >
          {t('user.nav.user_settings')}
        </NavLink>
      ) : null}
      {/*
        <NavLink
        className={`block border-b border-grey ${styles}`}
        to={`/${t('urls.account')}/${t('urls.user.notifications')}`}
        onClick={() => (closeMenu ? closeMenu() : null)}
      >
        {t('user.nav.notifications')}
      </NavLink> */}
      <NavLink
        className={`block border-b border-grey ${styles}`}
        to={`/${t('urls.account')}/${t('urls.user.review')}`}
        onClick={() => (closeMenu ? closeMenu() : null)}
      >
        {t('user.nav.review')}
      </NavLink>
      {/* <NavLink */}
      {/*  className={`block border-b border-grey ${styles}`} */}
      {/*  to={`/${t('urls.account')}/${t('urls.user.complaint')}`} */}
      {/*  onClick={() => (closeMenu ? closeMenu() : null)} */}
      {/* > */}
      {/*  {t('user.nav.complaint')} */}
      {/* </NavLink> */}

      <NavLink
        className={`block border-b border-grey ${styles}`}
        to={`/${t('urls.account')}/${t('urls.user.watched_products')}`}
        onClick={() => (closeMenu ? closeMenu() : null)}
      >
        {t('user.nav.watched_products')}
      </NavLink>

      <NavLink
        className={`block border-b border-grey ${styles}`}
        to={`/${t('urls.account')}/${t('urls.user.watched_sellers')}`}
        onClick={() => (closeMenu ? closeMenu() : null)}
      >
        {t('user.nav.watched_sellers')}
      </NavLink>

      {me.group === SELLER_GROUP ? (
        <NavLink
          className={`block border-b border-grey ${styles}`}
          to={`/${t('urls.account')}/${t('urls.user.finance_history')}`}
          onClick={() => (closeMenu ? closeMenu() : null)}
        >
          {t('user.nav.finance_history')}
        </NavLink>
      ) : null}

      {me.group === SELLER_GROUP ? (
        <NavLink
          className={`block border-b border-grey ${styles}`}
          to={`/${t('urls.account')}/${t('urls.user.absence')}`}
          onClick={() => (closeMenu ? closeMenu() : null)}
        >
          {t('user.nav.absence')}
        </NavLink>
      ) : null}

      <NavLink
        className={`block border-b border-grey ${styles}`}
        to={`/${t('urls.pages.manuals-and-files')}`}
        onClick={() => (closeMenu ? closeMenu() : null)}
      >
        {t('user.nav.manuals-and-files')}
      </NavLink>

      {me.group === SELLER_GROUP ? (
        <NavLink
          className={`block border-b border-grey ${styles}`}
          to={`/${t('urls.account')}/${t('urls.user.user_settings')}`}
          onClick={() => (closeMenu ? closeMenu() : null)}
        >
          {t('user.nav.user_settings')}
        </NavLink>
      ) : null}
      <Button
        text={t('user.nav.logout')}
        classNames="max-w-[160px]"
        onClick={() => {
          handleLogout()
          if (closeMenu) closeMenu()
        }}
      />
    </>
  )
}

export default UserMenu
