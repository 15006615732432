import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { api } from '../index'
import { Broadcast } from '../../@types/notifications'

const useBroadcastQuery = () => {
  const { i18n } = useTranslation()

  return useQuery({
    queryKey: ['broadcast', i18n.resolvedLanguage],
    queryFn: (): Promise<Broadcast[]> => {
      return api().get(`broadcast/${i18n.resolvedLanguage}`).json<Broadcast[]>()
    },
  })
}

export default useBroadcastQuery
