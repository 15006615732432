import { CookieConfig } from '../../@types/cookies'

export default <CookieConfig>{
  cs: {
    consent:
      'Na těchto stránkách používáme soubory cookies. K ukládání některých z nich potřebujeme Váš souhlas:',
    about:
      'Cookies jsou malá množství dat, ukládaná ve Vašem prohlížeči. Slouží ke správnému fungování platformy, lepšímu uživatelskému zážitku, personalizaci obsahu a reklam, analytice návštěvnosti a podobně. Zde můžete nastavit, které soubory cookie se mohou ukládat na Vašem prohlížeči. Více o využití cookies a podobných technologií se dozvíte na stránce <a href="https://numisdeal.com/cs/zasady-pouzivani-cookies">Zásady použití cookies</a>, kde je možné svůj souhlas s nastavením kdykoli změnit.',
  },
  en: {
    consent:
      'We use cookies on this website. We need your consent to store some of them:',
    about:
      'Cookies are small amounts of data stored in your browser. It is used for the correct functioning of the platform, better user experience, personalization of content and advertisements, traffic analysis and the like. Here you can set which cookies can be stored on your browser. You can learn more about the use of cookies and similar technologies on the page <a href="https://numisdeal.com/en/cookie-policy">Policy for the use of cookies</a>, where you can change your consent to the settings at any time .',
  },
  de: {
    consent:
      'Wir verwenden Cookies auf dieser Website. Für die Speicherung einiger davon benötigen wir Ihr Einverständnis:',
    about:
      'Cookies sind kleine Datenmengen, die in Ihrem Browser gespeichert werden. Sie dienen dem ordnungsgemäßen Funktionieren der Plattform, einem besseren Benutzererlebnis, der Personalisierung von Inhalten und Werbung, der Verkehrsanalyse und Ähnlichem. Hier können Sie einstellen, welche Cookies in Ihrem Browser gespeichert werden dürfen. Weitere Informationen zur Verwendung von Cookies und ähnlichen Technologien finden Sie auf der Seite <a href="https://numisdeal.com/de/cookies-richtlinie">Richtlinie zur Verwendung von Cookies</a>, wo Sie Sie können Ihre Einwilligung zu den Einstellungen jederzeit ändern.',
  },
  hu: {
    consent:
      'Sütiket használunk ezen a weboldalon. Az Ön beleegyezésére van szükségünk, hogy néhányat tároljunk:',
    about:
      'A sütik kis mennyiségű adat, amelyet az Ön böngészője tárol. A platform megfelelő működéséhez, a jobb felhasználói élményhez, a tartalom és a hirdetések személyre szabásához, forgalomelemzéshez és hasonlókhoz használják. Itt állíthatja be, hogy mely sütiket tárolja a böngészője. A cookie-k és hasonló technológiák használatáról a <a href="https://numisdeal.com/hu/cookie-szabalyzat">sütik használatára vonatkozó szabályzat</a> oldalon tudhat meg többet, ahol a beállításokhoz való hozzájárulását bármikor módosíthatja .',
  },
  sk: {
    consent:
      'Na týchto stránkach používame súbory cookies. Na ukladanie niektorých z nich potrebujeme Váš súhlas:',
    about:
      'Cookies sú malé množstvá dát, ukladané vo Vašom prehliadači. Slúži k správnemu fungovaniu platformy, lepšiemu užívateľskému zážitku, personalizácii obsahu a reklám, analytike návštevnosti a podobne. Tu môžete nastaviť, ktoré súbory cookie sa môžu ukladať na Vašom prehliadači. Viac o využití cookies a podobných technológií sa dozviete na stránke <a href="https://numisdeal.com/sk/zasady-pouzitia-cookies">Zásady použitia cookies</a>, kde je možné svoj súhlas s nastavením kedykoľvek zmeniť .',
  },
  name: 'Numisdeal',
  validity: 365,
  types: [],
  categoryTypes: [
    {
      id: 'ad_storage',
      cs: {
        name: 'Marketingové',
        description:
          'Marketingové cookies třetích stran slouží k personalizaci reklamního obsahu. Jsou využívány pro zobrazení zajímavější a relevantnější reklamy pro uživatele webových stránek.',
      },
      sk: {
        name: 'Marketingové',
        description:
          'Marketingové cookies tretích strán slúžia na personalizáciu reklamného obsahu. Sú využívané pre zobrazenie zaujímavejšej a relevantnejšej reklamy pre užívateľov webových stránok.',
      },
      en: {
        name: 'Marketing',
        description:
          '<p>Marketing cookies enable the personalization of marketing content on this website. They are primarily used to display relevant advertising for the user or website visitor.</p>',
      },
      hu: {
        name: 'Marketers',
        description:
          'A harmadik féltől származó marketing cookie-kat a hirdetési tartalom személyre szabására használjuk. Arra használják őket, hogy érdekesebb és relevánsabb hirdetéseket jelenítsenek meg a webhely felhasználói számára.',
      },
      de: {
        name: 'Vermarkter',
        description:
          'Marketing-Cookies von Drittanbietern werden zur Personalisierung von Werbeinhalten verwendet. Sie werden verwendet, um den Nutzern der Website interessantere und relevantere Werbung anzuzeigen.',
      },
      active: true,
      necessary: false,
    },

    {
      id: 'security_storage',
      cs: {
        name: 'Funkční',
        description:
          'Cookies nezbytné k provozu stránek. Slouží k ochraně uživatelského účtu, nastavení chování stránek a zajištění základních funkcí platformy.',
      },
      de: {
        name: 'Funktional',
        description:
          'Für den Betrieb der Website erforderliche Cookies.“ Sie dient dem Schutz des Benutzerkontos, der Einstellung des Verhaltens der Seiten und der Sicherstellung der Grundfunktionen der Plattform.',
      },
      hu: {
        name: 'Funkcionális',
        description:
          'Az oldal működéséhez szükséges cookie-k. A felhasználói fiók védelmét, az oldalak viselkedésének beállítását és a platform alapvető funkcióinak biztosítását szolgálja.',
      },
      sk: {
        name: 'Funkčné',
        description:
          'Cookies nevyhnutné na prevádzku stránok. Slúži na ochranu používateľského účtu, nastavenie správania stránok a zabezpečenie základných funkcií platformy.',
      },
      en: {
        name: 'Functional',
        description:
          'Cookies necessary for site operation. It serves to protect the user account, set the behavior of the pages and ensure the basic functions of the platform.',
      },
      active: true,
      necessary: true,
    },
    {
      id: 'personalization_storage',
      cs: {
        name: 'Preferenční',
        description:
          'Cookies používané k osobnímu přizpůsobení chování stránek. Patří sem například zapamatování volby jazyka a podobně.',
      },
      en: {
        name: 'Preferential',
        description:
          'Cookies used to personalize the behavior of the site. This includes, for example, remembering the choice of language and the like.',
      },
      sk: {
        name: 'Preferenčné',
        description:
          'Cookies používané na osobné prispôsobenie správania stránok. Patrí sem napríklad zapamätanie voľby jazyka a podobne.',
      },
      hu: {
        name: 'Preferenciális',
        description:
          'A webhely viselkedésének személyre szabására használt cookie-k. Ide tartozik például a nyelvválasztás és hasonlók emlékezése.',
      },
      de: {
        name: 'Bevorzugt',
        description:
          'Cookies werden verwendet, um das Verhalten der Website zu personalisieren. Dazu gehört beispielsweise das Erinnern an die Sprachwahl und Ähnliches.',
      },
      active: true,
      necessary: false,
    },
    {
      id: 'analytics_storage',
      cs: {
        name: 'Analytické',
        description:
          'Tyto cookies pomáhají provozovatelům lépe pochopit chování návštěvníků a tím zlepšovat běh platformy. Anonymizovaná data jsou využívána k vytváření statistik návštěvnosti, trendů atd.',
      },
      de: {
        name: 'Analytisch',
        description:
          'Diese Cookies helfen den Betreibern, das Verhalten der Besucher besser zu verstehen und dadurch den Betrieb der Plattform zu verbessern.“ Anonymisierte Daten werden zur Erstellung von Verkehrsstatistiken, Trends usw. verwendet.',
      },
      hu: {
        name: 'Analitikai',
        description:
          'Ezek a sütik segítenek az üzemeltetőknek abban, hogy jobban megértsék a látogatók viselkedését, és ezáltal javítsák a platform működését. Az anonimizált adatok forgalmi statisztikák, trendek stb. létrehozására szolgálnak.',
      },
      sk: {
        name: 'Analytické',
        description:
          'Tieto cookies pomáhajú prevádzkovateľom lepšie pochopiť správanie návštevníkov a tým zlepšovať beh platformy. Anonymizované dáta sú využívané na vytváranie štatistík návštevnosti, trendov atď.',
      },
      en: {
        name: 'Analytical',
        description:
          'These cookies help the operators to better understand the behavior of visitors and thereby improve the operation of the platform. Anonymized data is used to create traffic statistics, trends, etc.',
      },
      active: true,
      necessary: false,
    },
  ],
  categories: [
    {
      id: '1',
      categoryTypeFk: {
        id: 'security_storage',
      },
      providers: [
        {
          id: '2',
          cs: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com/',
          },
          en: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          de: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          sk: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          hu: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          details: [
            {
              id: '3',
              cs: {
                name: 'Numisdeal',
                description: 'Informace o souhlasu s nastavením cookies.',
                validity: '1 rok',
              },
              en: {
                name: 'Numisdeal',
                description:
                  'Information about consent to the setting of cookies.',
                validity: '1 year',
              },
              sk: {
                name: 'Numisdeal',
                description: 'Informácie o súhlase s nastavením cookies.',
                validity: '1 rok',
              },
              hu: {
                name: 'Numisdeal',
                description:
                  'Tájékoztatás a cookie-k beállításához való hozzájárulásról.',
                validity: '1 év',
              },
              de: {
                name: 'Numisdeal',
                description:
                  'Informationen zur Einwilligung in das Setzen von Cookies.',
                validity: '1 Jahr',
              },
              typeFk: {
                id: '4',
              },
            },
            {
              id: '13',
              cs: {
                name: 'Prestashop...',
                description: 'Funkční nastavení e-commerce systému.',
                validity: '1 měsíc',
              },
              de: {
                name: 'Prestashop...',
                description: 'Funktionseinstellungen des E-Commerce-Systems.',
                validity: '1 Monat',
              },
              en: {
                name: 'Prestashop...',
                description: 'Functional settings of the e-commerce system.',
                validity: '1 month',
              },
              hu: {
                name: 'Prestashop...',
                description:
                  'Az e-kereskedelmi rendszer funkcionális beállításai.',
                validity: '1 hónap',
              },
              sk: {
                name: 'Prestashop...',
                description: 'Funkčné nastavenia e-commerce systému.',
                validity: '1 mesiac',
              },
              typeFk: {
                id: '4',
              },
            },
          ],
        },
        /* {
          id: '14',
          cs: {
            name: 'Zendesk',
            link: 'https://www.zendesk.com/company/agreements-and-terms/privacy-notice/',
          },
          en: {
            name: 'Zendesk',
            link: 'https://www.zendesk.com/company/agreements-and-terms/privacy-notice/',
          },
          de: {
            name: 'Zendesk',
            link: 'https://www.zendesk.com/company/agreements-and-terms/privacy-notice/',
          },
          sk: {
            name: 'Zendesk',
            link: 'https://www.zendesk.com/company/agreements-and-terms/privacy-notice/',
          },
          hu: {
            name: 'Zendesk',
            link: 'https://www.zendesk.com/company/agreements-and-terms/privacy-notice/',
          },
          details: [
            {
              id: '15',
              cs: {
                name: '__zlcmid',
                description: 'Unikátní id k identifikaci session chatu.',
                validity: '1 rok',
              },
              sk: {
                name: '__zlcmid',
                description: 'Unikátny id na identifikáciu session chatu.',
                validity: '1 rok',
              },
              hu: {
                name: '__zlcmid',
                description:
                  'Egyedi azonosító a munkamenet-csevegés azonosításához.',
                validity: '1 év',
              },
              en: {
                name: '__zlcmid',
                description: 'Unique id to identify session chat.',
                validity: '1 year',
              },
              de: {
                name: '__zlcmid',
                description:
                  'Eindeutige ID zur Identifizierung des Sitzungschats.',
                validity: '1 Jahr',
              },
              typeFk: {
                id: '4',
              },
            },
          ],
        }, */
      ],
    },
    {
      id: '6',
      categoryTypeFk: {
        id: 'personalization_storage',
      },
      providers: [
        {
          id: '15',
          cs: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com/',
          },
          en: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          de: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          sk: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          hu: {
            name: 'Numisdeal',
            link: 'https://numisdeal.com',
          },
          details: [
            {
              id: '16',
              cs: {
                name: 'i18nextLng',
                description: 'Preferovaný jazyk uživatele',
                validity: 'trvalá',
              },
              en: {
                name: 'i18nextLng',
                description: "User's preferred language",
                validity: 'permanent',
              },
              sk: {
                názov: 'i18nextLng',
                description: 'Preferovaný jazyk používateľa',
                validity: 'trvalá',
              },
              hu: {
                name: 'i18nextLng',
                description: 'A felhasználó által preferált nyelv',
                validity: 'állandó',
              },
              de: {
                name: 'i18nextLng',
                description: 'Bevorzugte Sprache des Benutzers',
                validity: 'dauerhaft',
              },
              typeFk: {
                id: '4',
              },
            },
          ],
        },
      ],
    },
    {
      id: '7',
      categoryTypeFk: {
        id: 'analytics_storage',
      },
      providers: [
        {
          id: '8',
          cs: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          en: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          de: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          sk: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          hu: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          details: [
            {
              id: '10',
              cs: {
                name: '_ga...',
                description: 'Unikátní id pro měření statistických dat.',
                validity: '2 roky',
              },
              de: {
                name: '_ga...',
                description: 'Eindeutige ID zur Messung statistischer Daten.',
                validity: '2 Jahre',
              },
              en: {
                name: '_ga...',
                description: 'Unique id for measuring statistical data.',
                validity: '2 years',
              },
              hu: {
                name: '_ga...',
                description: 'Egyedi azonosító statisztikai adatok mérésére.',
                validity: '2 év',
              },
              sk: {
                name: '_ga...',
                description: 'Unikátny id na meranie štatistických dát.',
                validity: '2 roky',
              },
              typeFk: {
                id: '4',
              },
            },
          ],
        },
      ],
    },
    {
      id: '11',
      categoryTypeFk: {
        id: 'ad_storage',
      },
      providers: [
        {
          id: '12',
          cs: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          en: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          de: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          sk: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          hu: {
            name: 'Google',
            link: 'https://policies.google.com/privacy',
          },
          details: [
            {
              id: 'f74259ba-0dfb-4119-91a1-0e1a700510de',
              cs: {
                name: '_gcl_au',
                description:
                  'Data pro Google AdSense určená k měření efektivity reklamních kampaní.',
                validity: '3 měsíce',
              },
              sk: {
                name: '_gcl_au',
                description:
                  'Dáta pre Google AdSense určené na meranie efektivity reklamných kampaní.',
                validity: '3 mesiace',
              },
              hu: {
                name: '_gcl_au',
                description:
                  'A Google AdSense adatai a hirdetési kampányok hatékonyságának mérésére szolgálnak.',
                validity: '3 hónap',
              },
              en: {
                name: '_gcl_au',
                description:
                  'Data for Google AdSense intended to measure the effectiveness of advertising campaigns.',
                validity: '3 months',
              },
              de: {
                name: '_gcl_au',
                description:
                  'Daten für Google AdSense zur Messung der Wirksamkeit von Werbekampagnen.',
                validity: '3 Monate',
              },
              typeFk: {
                id: '4',
              },
            },
          ],
        },
      ],
    },
  ],
}
