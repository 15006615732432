/* eslint-disable risxss/catch-potential-xss-react */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { clsx } from 'clsx'
import {
  constructCategoryUrl,
  generateMenuTree,
  pickCategoryName,
  TMenuRowWithChildren,
} from '../utils/helperFunctions'
import CloseIcon from '../assets/icons/close_icon.svg'
import SearchInput from './reusableComponents/inputs/SearchInput'
import useBoundStore from '../slices/useBoundStore'
import useMeQuery from '../api/query/useMeQuery'
import UserMenu from './UserMenu'
import { useDesktopMenu } from '../utils/helperHooks'
import { useIsLoggedIn } from '../utils/auth'
import LanguageSwitch from './reusableComponents/LanguageSwitch'

/* start of mobile menu handling */
const handleFirstRowClick = (event: Event) => {
  const button = event.target as HTMLElement
  const subMenu = button
    .closest('div')
    ?.querySelector('.mobile-menu-second-row')

  document.querySelectorAll('.mobileMenu .active').forEach((el) => {
    if (el !== button && el !== subMenu) el.classList.remove('active')
  })

  // Toggle class "active" on clicked button
  button.classList.toggle('active')
  // Toggle class "active" on div next to clicked button
  subMenu?.classList.toggle('active')
}

const handleSecondRowClick = (event: Event) => {
  const button = event.target as HTMLElement
  // Toggle class "active" on clicked button
  button.classList.toggle('active')
  // Toggle class "active" on div next to clicked button
  const subMenu = button.closest('div')?.querySelector('.mobile-menu-third-row')
  subMenu?.classList.toggle('active')
}

const handleThirdRowClick = (event: Event) => {
  const button = event.target as HTMLElement
  // Toggle class "active" on clicked button
  button.classList.toggle('active')
  // Toggle class "active" on div next to clicked button
  const subMenu = button
    .closest('div')
    ?.querySelector('.mobile-menu-fourth-row')
  subMenu?.classList.toggle('active')
}
/* end of mobile menu handling */

type TMenuProps = {
  closePopover: () => void
}

const Menu: FC<TMenuProps> = ({ closePopover }) => {
  const { t } = useTranslation()
  const menuTree = useMemo(() => generateMenuTree(), [])
  const { pathname } = useLocation()
  const isDesktop = useDesktopMenu()
  const { data: me } = useMeQuery()
  const isLoggedIn = useIsLoggedIn()

  /* states for opening desktop submenus */
  const [desktopMenuIndexes, setDesktopMenuIndexes] = useState({
    firstRow: -1,
    secondRow: -1,
    thirdRow: -1,
  })
  /* end of states for opening desktop submenus */
  const [mobileMenuType, setMobileMenuType] = useState<
    'categories' | 'profile'
  >(pathname.includes(t('urls.account')) ? 'profile' : 'categories')

  const eventListenersSet = useRef<boolean>(false)
  const { setSearchQuery } = useBoundStore()
  const navigate = useNavigate()

  const setMobileMenu = (type: 'profile' | 'categories') => {
    setMobileMenuType(type)
    // Required for mobile menu event listeners, otherwise they will not mount again
    eventListenersSet.current = false
  }

  useEffect(() => {
    // stop body scrolling when menu opens
    document.body.style.overflowY = 'hidden'

    // React Strict mode fires useEffect twice in dev mode and breaks toggling of classes, ref fixes this
    if (!eventListenersSet.current && mobileMenuType === 'categories') {
      eventListenersSet.current = true

      document
        .querySelectorAll('.mobileMenu .mobile-menu-first-row-button')
        .forEach((el) =>
          el.addEventListener('click', (event) => handleFirstRowClick(event))
        )

      document
        .querySelectorAll('.mobileMenu .mobile-menu-second-row-button')
        .forEach((el) =>
          el.addEventListener('click', (event) => handleSecondRowClick(event))
        )

      document
        .querySelectorAll('.mobileMenu .mobile-menu-third-row-button')
        .forEach((el) =>
          el.addEventListener('click', (event) => handleThirdRowClick(event))
        )
    }

    return () => {
      // Restore body scrolling after menu unmount
      document.body.style.overflowY = 'initial'

      document
        .querySelectorAll('.mobileMenu .mobile-menu-first-row-button')
        .forEach((el) =>
          el.removeEventListener('click', (event) => handleFirstRowClick(event))
        )
      document
        .querySelectorAll('.mobileMenu .mobile-menu-second-row-button')
        .forEach((el) =>
          el.removeEventListener('click', (event) =>
            handleSecondRowClick(event)
          )
        )
      document
        .querySelectorAll('.mobileMenu .mobile-menu-third-row-button')
        .forEach((el) =>
          el.removeEventListener('click', (event) => handleThirdRowClick(event))
        )
    }
  }, [mobileMenuType])

  return (
    <div className="mt-3 w-full px-6 max-lg:flex max-lg:flex-col lg:ml-auto lg:mr-6 lg:mt-16 lg:w-[95%]">
      <div className="mb-3 flex items-center justify-between lg:mb-8">
        <div>
          <button
            type="button"
            onClick={() => setMobileMenu('categories')}
            className={clsx('cursor-pointer text-[20px] lg:hidden', {
              'text-bronze underline': mobileMenuType === 'categories',
            })}
          >
            {t('header.menu')}
          </button>
          <button
            type="button"
            onClick={() => setMobileMenu('profile')}
            className={clsx('ml-5  text-[20px] lg:hidden', {
              'text-bronze underline': mobileMenuType === 'profile',
            })}
          >
            {t('header.my_account')}
          </button>
          <span className="hidden font-bold text-[2rem] lg:inline">
            {t('header.menu')}
          </span>
        </div>
        <button
          type="button"
          className="max-lg:w-[35px]"
          onClick={() => closePopover()}
        >
          <img src={CloseIcon} alt="close button" />
        </button>
      </div>
      <div
        className={clsx('border-t border-white-darker py-2 lg:hidden', {
          'mb-3 border-b':
            mobileMenuType === 'categories' ||
            (isLoggedIn && mobileMenuType === 'profile' && !isDesktop),
        })}
      >
        <LanguageSwitch />
      </div>
      <div className="hidden lg:flex">
        <span className="my-1.5 w-1/4 max-w-[250px] py-1 pr-6 font-regular text-grey-darker">
          {t('header.choose_from_categories')}
        </span>
        {desktopMenuIndexes.firstRow >= 0 ? (
          <div className="w-1/4 max-w-[300px] pl-10 pr-6">
            <NavLink
              to={constructCategoryUrl(menuTree[desktopMenuIndexes.firstRow])}
              className="my-1.5 block py-1"
              onClick={() => closePopover()}
            >
              {t('header.show_all')}
            </NavLink>
          </div>
        ) : null}
        {desktopMenuIndexes.firstRow >= 0 &&
        desktopMenuIndexes.secondRow >= 0 ? (
          <div className="w-1/4 max-w-[300px] pl-10 pr-6">
            <NavLink
              to={constructCategoryUrl(
                menuTree[desktopMenuIndexes.firstRow].children[
                  desktopMenuIndexes.secondRow
                ]
              )}
              className="my-1.5 block py-1"
              onClick={() => closePopover()}
            >
              {t('header.show_all')}
            </NavLink>
          </div>
        ) : null}
        {desktopMenuIndexes.firstRow >= 0 &&
        desktopMenuIndexes.secondRow >= 0 &&
        desktopMenuIndexes.thirdRow >= 0 ? (
          <div className="w-1/4 max-w-[300px] pl-10 pr-6">
            <NavLink
              to={constructCategoryUrl(
                menuTree[desktopMenuIndexes.firstRow].children[
                  desktopMenuIndexes.secondRow
                ].children[desktopMenuIndexes.thirdRow]
              )}
              className="my-1.5 block py-1"
              onClick={() => closePopover()}
            >
              {t('header.show_all')}
            </NavLink>
          </div>
        ) : null}
      </div>
      {mobileMenuType !== 'profile' ? (
        <SearchInput
          placeholder={t('header.search')}
          onSubmit={(value) => {
            setSearchQuery(value)
            closePopover()
            navigate(`/${t('urls.search')}`)
          }}
          wrapperClassNames="bg-white-lighter w-full flex lg:hidden mb-3 ml-auto mr-auto"
          // showClearButton
        />
      ) : null}

      {isDesktop ? (
        <div className="flex">
          <div className="scroll-shadow custom-thin-scrollbar text-md h-[70vh] w-1/4 max-w-[250px] overflow-y-auto pr-6 font-medium">
            {
              // menuTree is array from 3 arrays of parent categories - NUMISMATIKA, BANKOVKY, VYZNAMENÁNÍ
              menuTree.map((firstRow, index) => {
                return firstRow.children ? (
                  <button
                    type="button"
                    className={clsx(
                      'desktop-menu-button relative my-1.5 block w-full py-1 pr-5 text-left transition-colors duration-100 hover:text-bronze',
                      { 'text-bronze': index === desktopMenuIndexes.firstRow }
                    )}
                    key={firstRow.id}
                    onClick={() => {
                      setDesktopMenuIndexes((prevState) => ({
                        firstRow: prevState.firstRow === index ? -1 : index,
                        secondRow: -1,
                        thirdRow: -1,
                      }))
                    }}
                    dangerouslySetInnerHTML={{
                      __html: pickCategoryName(firstRow),
                    }}
                  />
                ) : (
                  <NavLink
                    key={firstRow.id}
                    to={constructCategoryUrl(firstRow)}
                    className="my-1.5 block py-1"
                    onClick={() => closePopover()}
                    dangerouslySetInnerHTML={{
                      __html: pickCategoryName(firstRow),
                    }}
                  />
                )
              })
            }

            <NavLink
              to={`${t('urls.category')}/2-${t('urls.latest')}`}
              className="my-1.5 block py-1 uppercase"
              onClick={() => closePopover()}
            >
              {t('header.new_products')}
            </NavLink>

            <span className="mb-1 inline-block h-[1px] w-full bg-white-darker" />
            <NavLink
              to={t('urls.pages.about-us')}
              className="my-1.5 block py-1"
              onClick={() => closePopover()}
            >
              {t('header.about')}
            </NavLink>
            <NavLink
              to={t('urls.contact')}
              className="my-1.5 block py-1"
              onClick={() => closePopover()}
            >
              {t('header.contacts')}
            </NavLink>
            <NavLink
              to={`/${t('urls.pages.how-to-become-a-seller')}`}
              className="my-1.5 block py-1"
              onClick={() => closePopover()}
            >
              {t('header.how_to_become_seller')}
            </NavLink>
            <span className="mb-1 inline-block h-[1px] w-full bg-white-darker" />
            {isLoggedIn ? (
              <NavLink
                to={`/${t('urls.account')}`}
                className="my-1.5 block py-1"
                onClick={() => closePopover()}
              >
                {t('header.my_account')}
              </NavLink>
            ) : (
              <NavLink
                to={`/${t('urls.login')}`}
                className="my-1.5 block py-1"
                onClick={() => closePopover()}
              >
                {t('header.log_in')}
              </NavLink>
            )}
          </div>

          {desktopMenuIndexes.firstRow >= 0 ? (
            <div className="scroll-shadow custom-thin-scrollbar text-md h-[70vh] w-1/4 max-w-[300px] overflow-y-auto border-l border-white-darker pl-10 pr-6 font-medium">
              {/* second row from Excel - Starověk, středověk, habsburgovci,... */}
              {menuTree[desktopMenuIndexes.firstRow].children.map(
                (secondRow: TMenuRowWithChildren, index: number) => {
                  return secondRow.children ? (
                    <button
                      type="button"
                      className={clsx(
                        'desktop-menu-button relative my-1.5 block w-full py-1 pr-4 text-left transition-colors duration-100 hover:text-bronze',
                        {
                          'text-bronze': index === desktopMenuIndexes.secondRow,
                        }
                      )}
                      key={secondRow.id}
                      onClick={() =>
                        setDesktopMenuIndexes((prevState) => ({
                          ...prevState,
                          secondRow: prevState.secondRow === index ? -1 : index,
                          thirdRow: -1,
                        }))
                      }
                      dangerouslySetInnerHTML={{
                        __html: pickCategoryName(secondRow),
                      }}
                    />
                  ) : (
                    <NavLink
                      key={secondRow.id}
                      to={constructCategoryUrl(secondRow)}
                      className="my-1.5 block py-1"
                      onClick={() => closePopover()}
                      dangerouslySetInnerHTML={{
                        __html: pickCategoryName(secondRow),
                      }}
                    />
                  )
                }
              )}
            </div>
          ) : null}

          {desktopMenuIndexes.firstRow >= 0 &&
          desktopMenuIndexes.secondRow >= 0 ? (
            <div className="scroll-shadow custom-thin-scrollbar text-md h-[70vh] w-1/4 max-w-[300px] overflow-y-auto border-l border-white-darker pl-10 pr-6 font-medium ">
              {menuTree[desktopMenuIndexes.firstRow].children[
                desktopMenuIndexes.secondRow
              ].children.map(
                (thirdRow: TMenuRowWithChildren, index: number) => {
                  return thirdRow.children ? (
                    //
                    <button
                      type="button"
                      className={clsx(
                        'desktop-menu-button relative my-1.5 block w-full py-1 pr-4 text-left transition-colors duration-100 hover:text-bronze',
                        { 'text-bronze': index === desktopMenuIndexes.thirdRow }
                      )}
                      key={thirdRow.id}
                      onClick={() =>
                        setDesktopMenuIndexes((prevState) => ({
                          ...prevState,
                          thirdRow: prevState.thirdRow === index ? -1 : index,
                        }))
                      }
                      dangerouslySetInnerHTML={{
                        __html: pickCategoryName(thirdRow),
                      }}
                    />
                  ) : (
                    <NavLink
                      key={thirdRow.id}
                      to={constructCategoryUrl(thirdRow)}
                      className="my-1.5 block py-1"
                      onClick={() => closePopover()}
                      dangerouslySetInnerHTML={{
                        __html: pickCategoryName(thirdRow),
                      }}
                    />
                  )
                }
              )}
            </div>
          ) : null}

          {desktopMenuIndexes.firstRow >= 0 &&
          desktopMenuIndexes.secondRow >= 0 &&
          desktopMenuIndexes.thirdRow >= 0 ? (
            <div className="scroll-shadow custom-thin-scrollbar text-md h-[70vh] w-1/4 max-w-[300px] overflow-y-auto border-l border-white-darker pl-10 pr-1 font-medium ">
              {menuTree[desktopMenuIndexes.firstRow].children[
                desktopMenuIndexes.secondRow
              ].children[desktopMenuIndexes.thirdRow].children.map(
                (fourthRow: TMenuRowWithChildren) => (
                  <NavLink
                    key={fourthRow.id}
                    to={constructCategoryUrl(fourthRow)}
                    className="my-1.5 block py-1"
                    onClick={() => closePopover()}
                    dangerouslySetInnerHTML={{
                      __html: pickCategoryName(fourthRow),
                    }}
                  />
                )
              )}
            </div>
          ) : null}
        </div>
      ) : (
        /* mobile menu start */
        <div className="mobileMenu w-full overflow-y-auto">
          {mobileMenuType === 'categories' ? (
            <>
              {menuTree.map((firstRow) => {
                return firstRow.children ? (
                  <div key={`mobile-${firstRow.id}`}>
                    <button
                      type="button"
                      className="mobile-menu-first-row-button mobile-menu-row-button relative block w-full border-t border-white-darker py-2.5 text-left text-[1.375rem] text-black-lighter transition-colors duration-100 hover:text-bronze"
                      dangerouslySetInnerHTML={{
                        __html: pickCategoryName(firstRow),
                      }}
                    />
                    <div className="mobile-menu-second-row mobile-menu-row">
                      <NavLink
                        to={constructCategoryUrl(firstRow)}
                        className="block py-2.5 pl-4 text-lg text-black-lighter"
                        onClick={() => closePopover()}
                      >
                        {t('header.show_all')}
                      </NavLink>
                      {firstRow.children.map(
                        (secondRow: TMenuRowWithChildren) => {
                          return secondRow.children ? (
                            <div key={`mobile-${secondRow.id}`}>
                              <button
                                type="button"
                                className="mobile-menu-second-row-button mobile-menu-row-button relative block w-full py-2.5 pl-4 text-left text-lg text-black-lighter transition-colors duration-100 hover:text-bronze"
                                dangerouslySetInnerHTML={{
                                  __html: pickCategoryName(secondRow),
                                }}
                              />
                              <div className="mobile-menu-third-row mobile-menu-row">
                                <NavLink
                                  to={constructCategoryUrl(secondRow)}
                                  className="block py-2.5 pl-8 text-lg text-black-lighter"
                                  onClick={() => closePopover()}
                                >
                                  {t('header.show_all')}
                                </NavLink>
                                {secondRow.children.map(
                                  (thirdRow: TMenuRowWithChildren) => {
                                    return thirdRow.children ? (
                                      <div key={`mobile-${thirdRow.id}`}>
                                        <button
                                          type="button"
                                          className="mobile-menu-third-row-button mobile-menu-row-button relative block w-full py-2.5 pl-8 text-left text-lg text-black-lighter transition-colors duration-100 hover:text-bronze"
                                          dangerouslySetInnerHTML={{
                                            __html: pickCategoryName(thirdRow),
                                          }}
                                        />
                                        <div className="mobile-menu-fourth-row mobile-menu-row">
                                          <NavLink
                                            to={constructCategoryUrl(thirdRow)}
                                            className="block py-2.5 pl-12 text-lg text-black-lighter"
                                            onClick={() => closePopover()}
                                          >
                                            {t('header.show_all')}
                                          </NavLink>
                                          {thirdRow.children.map(
                                            (fourthRow) => (
                                              <NavLink
                                                key={`mobile-${fourthRow.id}`}
                                                to={constructCategoryUrl(
                                                  fourthRow
                                                )}
                                                className="block py-2.5 pl-12 text-lg text-black-lighter"
                                                onClick={() => closePopover()}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    pickCategoryName(fourthRow),
                                                }}
                                              />
                                            )
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <NavLink
                                        key={`mobile-${thirdRow.id}`}
                                        to={constructCategoryUrl(thirdRow)}
                                        className="block py-2.5 pl-8 text-lg text-black-lighter"
                                        onClick={() => closePopover()}
                                        dangerouslySetInnerHTML={{
                                          __html: pickCategoryName(thirdRow),
                                        }}
                                      />
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          ) : (
                            <NavLink
                              key={`mobile-${secondRow.id}`}
                              to={constructCategoryUrl(secondRow)}
                              className="block py-2.5 pl-4 text-lg text-black-lighter"
                              onClick={() => closePopover()}
                              dangerouslySetInnerHTML={{
                                __html: pickCategoryName(secondRow),
                              }}
                            />
                          )
                        }
                      )}
                    </div>
                  </div>
                ) : (
                  <NavLink
                    key={`mobile-${firstRow.id}`}
                    to={constructCategoryUrl(firstRow)}
                    className="block border-t border-white-darker py-2.5 text-[1.375rem] text-black-lighter"
                    onClick={() => closePopover()}
                    dangerouslySetInnerHTML={{
                      __html: pickCategoryName(firstRow),
                    }}
                  />
                )
              })}

              <NavLink
                to={`${t('urls.category')}/2-${t('urls.latest')}`}
                className="block w-full border-t border-white-darker py-2.5 text-left text-[1.375rem] uppercase text-black-lighter transition-colors duration-100 hover:text-bronze"
                onClick={() => closePopover()}
              >
                {t('header.new_products')}
              </NavLink>
              <NavLink
                to={t('urls.pages.about-us')}
                className="block w-full border-t border-white-darker py-2.5 text-left text-[1.375rem] text-black-lighter transition-colors duration-100 hover:text-bronze"
                onClick={() => closePopover()}
              >
                {t('header.about')}
              </NavLink>
              <NavLink
                to={t('urls.contact')}
                className="block w-full border-t border-white-darker py-2.5 text-left text-[1.375rem] text-black-lighter transition-colors duration-100 hover:text-bronze"
                onClick={() => closePopover()}
              >
                {t('header.contacts')}
              </NavLink>
              <NavLink
                to={`/${t('urls.pages.how-to-become-a-seller')}`}
                className="block w-full border-t border-white-darker py-2.5 text-left text-[1.375rem] text-black-lighter transition-colors duration-100 hover:text-bronze"
                onClick={() => closePopover()}
              >
                {t('header.how_to_become_seller')}
              </NavLink>
            </>
          ) : (
            <div>
              {isLoggedIn && me ? (
                <UserMenu
                  me={me}
                  closeMenu={closePopover}
                  isDesktop={isDesktop}
                />
              ) : (
                <>
                  <NavLink
                    to={`/${t('urls.login')}`}
                    className="block w-full border-t border-white-darker py-2.5 text-left text-[1.375rem] text-black-lighter transition-colors duration-100 hover:text-bronze"
                    onClick={() => closePopover()}
                  >
                    {t('header.log_in')}
                  </NavLink>
                  <NavLink
                    to={`/${t('urls.register_account')}`}
                    className="block w-full border-t border-white-darker py-2.5 text-left text-[1.375rem] text-black-lighter transition-colors duration-100 hover:text-bronze"
                    onClick={() => closePopover()}
                  >
                    {t('registration.registration')}
                  </NavLink>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default Menu
