import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Transition, Popover } from '@headlessui/react'
import { Fragment } from 'react'
import Logo from '../assets/logo_desktop.svg'
// import NotificationIcon from '../assets/icons/notification_icon.svg'
import AccountIcon from '../assets/icons/account_icon.svg'
import CartIcon from '../assets/icons/cart_icon.svg'
import MenuHamburgerIcon from '../assets/icons/menu_hamburger_icon.svg'
import NotificationIcon from '../assets/icons/notification_icon.svg'
import SearchInput from './reusableComponents/inputs/SearchInput'
// import DotNotification from './reusableComponents/DotNotification'
import useBoundStore from '../slices/useBoundStore'
// import { supportedCurrencies } from '../slices/createGlobalSlice'
import Menu from './Menu'
import { useCartData } from '../utils/helperHooks'
// import NotificationsPopover from './NotificationsPopover'
// import useNotificationsQuery from '../api/query/useNotificationsQuery'
import Loader from './reusableComponents/Loader'
import LanguageSwitch from './reusableComponents/LanguageSwitch'
import { useIsLoggedIn } from '../utils/auth'
import NotificationsPopover from './NotificationsPopover'
import DotNotification from './reusableComponents/DotNotification'
import useBroadcastQuery from '../api/query/useBroadcastQuery'

const Header = () => {
  const { t } = useTranslation()
  const { currency, setSearchQuery } = useBoundStore()
  const { prices, isLoading: isLoadingData } = useCartData()
  const loggedIn = useIsLoggedIn()
  const { data: notifications } = useBroadcastQuery()

  const navigate = useNavigate()

  return (
    <div className="bg-white">
      <div className="hidden border-b border-b-white-darker lg:block">
        {/* First row */}
        <div className="container flex items-center justify-between px-4 py-3 font-medium">
          <div className="flex items-center">
            {/* <a href="tel:+420 123 456 789">+420 123 456 789</a> */}
            <Link to={t('urls.contact')}>{t('header.contacts')}</Link>
            <span className="mx-6 inline-block h-[18px] w-0.5 border-r border-grey" />
            <a href="mailto:info@numisdeal.com">info@numisdeal.com</a>
          </div>
          <div>
            {/* <span> */}
            {/*  Země doručení:{' '} */}
            {/*  <span className="relative text-bronze dropdown-icon cursor-pointer mr-8"> */}
            {/*    CZ */}
            {/*  </span> */}
            {/* </span> */}
            {/* Currency change */}
            {/* <Popover as="span" className="relative"> */}
            {/*  <Popover.Button className="inline-flex justify-center items-center mr-4"> */}
            {/*    {t('header.currency')}: */}
            {/*    <span className="text-bronze relative ml-0.5"> */}
            {/*      {currency.name} */}
            {/*    </span> */}
            {/*    <img src={DropdownIcon} className="ml-2" alt="" /> */}
            {/*  </Popover.Button> */}
            {/*  <Transition */}
            {/*    as={Fragment} */}
            {/*    enter="transition ease-out duration-100" */}
            {/*    enterFrom="opacity-0 translate-y-1" */}
            {/*    enterTo="opacity-100 translate-y-0" */}
            {/*    leave="transition ease-in duration-50" */}
            {/*    leaveFrom="opacity-100 translate-y-0" */}
            {/*    leaveTo="opacity-0 translate-y-1" */}
            {/*  > */}
            {/*    <Popover.Panel className="absolute z-30 w-24 shadow-md left-1/2 -translate-x-1/2 transform bg-white-lighter rounded-md p-1"> */}
            {/*      {supportedCurrencies.map((c) => ( */}
            {/*        <button */}
            {/*          key={`${c.id}-${c.name}`} */}
            {/*          type="button" */}
            {/*          className="w-full my-1 hover:text-bronze" */}
            {/*          onClick={() => setCurrency(c)} */}
            {/*        > */}
            {/*          {c.name} */}
            {/*        </button> */}
            {/*      ))} */}
            {/*    </Popover.Panel> */}
            {/*  </Transition> */}
            {/* </Popover> */}
            {/* End of currency change */}
            {!loggedIn && (
              <div className="mx-4 inline-block">
                <Link to={`/${t('urls.login')}`}>{t('login.login')}</Link>
                &nbsp;|&nbsp;
                <Link to={`/${t('urls.register_account')}`}>
                  {t('registration.registration')}
                </Link>
              </div>
            )}
            {/* Language change */}
            <LanguageSwitch />
            {/* End of language change */}
          </div>
        </div>
      </div>
      {/* End of first row */}
      {/* Second row */}
      <div className="container flex items-center justify-between py-6">
        <Link to="/" className="">
          <img src={Logo} alt="Numisdeal logo" width={143} height={38} />
        </Link>
        <SearchInput
          placeholder={t('header.search')}
          onSubmit={(value) => {
            setSearchQuery(value)
            navigate(`/${t('urls.search')}`)
          }}
          wrapperClassNames="bg-white-lighter w-6/12 lg:w-full hidden md:flex"
          showClearButton
        />
        <div className="flex items-center justify-end">
          <Popover
            as="span"
            className="relative flex items-center justify-center"
          >
            <Popover.Button className="relative mr-3 outline-none">
              {notifications && notifications.length > 0 ? (
                <DotNotification />
              ) : null}
              <img
                src={NotificationIcon}
                className="h-[24px] w-[24px] max-w-none"
                alt="Notification icon"
              />
            </Popover.Button>
            {notifications && !!notifications.length && (
              <>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0"
                  enterTo="opacity-20"
                  leave="transition ease-in duration-50"
                  leaveFrom="opacity-20"
                  leaveTo="opacity-0"
                >
                  <Popover.Overlay className="fixed inset-0 z-20 bg-black" />
                </Transition>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-50"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="fixed right-0 top-0 z-30 w-full transform bg-white max-md:bottom-0 md:absolute md:top-[30px] md:w-96 md:rounded-md">
                    {({ close }) => (
                      <NotificationsPopover
                        notifications={notifications}
                        closePopover={close}
                      />
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <button
            type="button"
            className="mr-3"
            onClick={() => navigate(`/${t('urls.account')}`)}
          >
            <img
              src={AccountIcon}
              className="h-[24px] w-[24px] max-w-none"
              alt="Account icon"
            />
          </button>
          <button
            type="button"
            className="flex items-center font-bold"
            onClick={() => navigate(`/${t('urls.cart')}`)}
          >
            <span className="relative">
              {/* data?.items.length ? <DotNotification /> : null */}
              <img
                src={CartIcon}
                className="h-[24px] w-[24px] max-w-none"
                alt="Cart icon"
              />
            </span>
            <span className="ml-3 hidden md:inline">
              {isLoadingData ? (
                <Loader py="py-0" size="h-[18px] w-[18px]" />
              ) : (
                <>
                  {prices.totalPrice.toLocaleString()} {currency.sign}
                </>
              )}
            </span>
          </button>
        </div>
      </div>
      {/* End of second row */}
      {/* Third row */}
      <div className="border-t border-white-darker bg-white py-2.5">
        <div className="container flex items-center justify-between font-semibold">
          <div className="flex items-center justify-start">
            <Popover>
              <Popover.Button
                type="button"
                className="flex py-2 focus-visible:outline-0"
              >
                <img
                  src={MenuHamburgerIcon}
                  alt="Menu hamburger"
                  className="mr-3"
                />
                <span>{t('header.menu')}</span>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-20"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-20"
                leaveTo="opacity-0"
              >
                <Popover.Overlay className="fixed inset-0 z-40 bg-black" />
              </Transition>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 -translate-x-[100%]"
                enterTo="opacity-100 translate-x-0"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-[100%]"
              >
                <Popover.Panel className="fixed bottom-0 left-0 top-0 z-50 w-full max-w-[1400px] bg-white max-lg:flex lg:w-full">
                  {({ close }) => <Menu closePopover={close} />}
                </Popover.Panel>
              </Transition>
            </Popover>
            <NavLink
              to={`/${t('urls.category')}/2-${t('urls.latest')}`}
              className="ml-7 flex items-center fill-black hover:fill-bronze 2xs:ml-10"
            >
              <svg
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="inherit"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7074 2.8149C10.8136 1.32212 9.59489 0.5 8.27273 0.5H4.72727C3.40511 0.5 2.18636 1.32212 1.29261 2.8149C0.398864 4.30769 0 6.05288 0 8C0 9.94711 0.457954 11.7933 1.29261 13.1851C2.12727 14.5769 3.40511 15.5 4.72727 15.5H8.27273C9.59489 15.5 10.8136 14.6779 11.7074 13.1851C12.6011 11.6923 13 9.94711 13 8C13 6.05288 12.542 4.20673 11.7074 2.8149ZM11.8034 7.42308H9.43977C9.38068 6.18269 9.13693 4.99279 8.7233 3.96154H10.9835C11.4341 4.92788 11.7369 6.125 11.8034 7.42308ZM10.2818 2.80769H8.16193C7.91182 2.39178 7.61449 2.00473 7.27557 1.65385H8.27273C9.01136 1.65385 9.70568 2.08654 10.2818 2.80769ZM1.18182 8C1.18182 4.5601 2.80682 1.65385 4.72727 1.65385C6.64773 1.65385 8.27273 4.5601 8.27273 8C8.27273 11.4399 6.64773 14.3462 4.72727 14.3462C2.80682 14.3462 1.18182 11.4399 1.18182 8ZM8.27273 14.3462H7.27557C7.61449 13.9953 7.91182 13.6082 8.16193 13.1923H10.2818C9.70568 13.9135 9.01136 14.3462 8.27273 14.3462ZM10.9835 12.0385H8.7233C9.13693 11.0072 9.38068 9.81731 9.43977 8.57692H11.8034C11.7369 9.875 11.4341 11.0721 10.9835 12.0385Z"
                  fill="inherit"
                />
              </svg>
              <span className="ml-1.5 max-2xs:text-sm">
                {t('header.new_products')}
              </span>
            </NavLink>
          </div>
          <div className="hidden xl:inline">
            <NavLink
              to={`/${t('urls.category')}/3-${t('header.category.coins')}`}
              className="mr-8 p-2"
            >
              {t('header.coins')}
            </NavLink>
            <NavLink
              to={`/${t('urls.category')}/141-${t(
                'header.category.paper_money'
              )}`}
              className="mr-8 p-2"
            >
              {t('header.paper_money')}
            </NavLink>
            <NavLink
              to={`/${t('urls.category')}/188-${t('header.category.honours')}`}
              className="mr-8 p-2"
            >
              {t('header.honours')}
            </NavLink>
            <NavLink
              to={`/${t('urls.category')}/93-${t(
                'header.category.gold_coins'
              )}`}
              className="mr-8 p-2"
            >
              {t('header.gold_coins')}
            </NavLink>
            {/* <NavLink
              to={`/${t('urls.category')}/223-${t('header.category.actions')}`}
              className="mr-8 p-2"
            >
              {t('header.actions')}
              </NavLink> */}
            <NavLink
              to={`/${t('urls.category')}/224-${t(
                'header.category.accessories'
              )}`}
              className="mr-8 p-2"
            >
              {t('header.accessories')}
            </NavLink>
          </div>
        </div>
      </div>
      {/* End of third row */}
    </div>
  )
}

export default Header
