import Cookies from 'js-cookie'
import {
  useState,
  createContext,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import config from './config'
import { CookieCategoryType } from '../../@types/cookies'

interface CookieContextType {
  modalShown: boolean
  cookieSet: boolean
  acknowledgeCookies: (categories?: string[], firstCall?: boolean) => void
  showModal: Dispatch<SetStateAction<boolean>>
}

interface CookieProviderProps {
  children: JSX.Element[]
}

const CookieContext = createContext<CookieContextType | null>(null)

function CookieProvider({ children }: CookieProviderProps) {
  const [modalShown, showModal] = useState(false)
  const [cookieSet, isCookieSet] = useState(false)

  const acknowledgeCookies = useCallback(
    (categories: string[] = [], firstCall = false) => {
      if (config) {
        const necessary = config.categoryTypes.filter(
          (cT: CookieCategoryType) => cT.active && cT.necessary
        )
        const selected = [
          ...necessary.map((cT: CookieCategoryType) => cT.id),
          ...categories,
        ].filter((v, i, a) => a.indexOf(v) === i)
        const oldCookieString = Cookies.get(config.name)
        const newCookieString = selected.join(',')

        if (!oldCookieString || oldCookieString !== newCookieString) {
          Cookies.set(config.name, newCookieString, {
            expires: config.validity,
            sameSite: 'Lax',
          })
        }

        if (window.dataLayer && typeof window.dataLayer.push === 'function') {
          const consent = config.categoryTypes
            .filter((cT: CookieCategoryType) => cT.active)
            .map((cT: CookieCategoryType) => [
              cT.id,
              selected.includes(cT.id) ? 'granted' : 'denied',
            ])

          window.dataLayer.push([
            'consent',
            firstCall ? 'default' : 'update',
            Object.fromEntries(consent),
          ])
        }

        isCookieSet(true)
      }
    },
    []
  )

  useEffect(() => {
    const cookies = Cookies.get(config.name)?.split(',')
    if (cookies && cookies.length) {
      isCookieSet(true)
      acknowledgeCookies(cookies, true)
    } else if (
      window.dataLayer &&
      typeof window.dataLayer.push === 'function'
    ) {
      const consent = config.categoryTypes.map((cT: CookieCategoryType) => [
        cT.id,
        cT.active && cT.necessary ? 'granted' : 'denied',
      ])

      window.dataLayer.push(['consent', 'default', Object.fromEntries(consent)])
    }
  }, [isCookieSet, acknowledgeCookies])

  const value = useMemo(
    () => ({
      modalShown,
      cookieSet,
      acknowledgeCookies,
      showModal,
    }),
    [modalShown, showModal, acknowledgeCookies, cookieSet]
  )

  return (
    <CookieContext.Provider value={value}>{children}</CookieContext.Provider>
  )
}

export { CookieContext, CookieProvider }
