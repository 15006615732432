import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import DropdownIcon from '../../assets/icons/dropdown_arrow_yellow_icon.svg'
import { changeLanguage, supportedLanguages } from '../../i18next'

const LanguageSwitch = () => {
  const { t, i18n } = useTranslation()

  return (
    <Popover as="span" className="relative">
      <Popover.Button className="inline-flex items-center justify-center">
        {t('header.language')}:
        <span className="relative ml-0.5 text-bronze">
          {i18n.resolvedLanguage?.toUpperCase()}
        </span>
        <img src={DropdownIcon} className="ml-2" alt="" />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-50"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-50 w-32 -translate-x-1/2 transform rounded-md bg-white drop-shadow-lg">
          {supportedLanguages.map((lang) => (
            <button
              key={lang}
              type="button"
              className="block w-full border-t border-grey py-1.5 pl-6 text-left first:border-none hover:text-bronze"
              onClick={() => changeLanguage(lang)}
            >
              {t(`common.${lang}`)}
            </button>
          ))}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default LanguageSwitch
