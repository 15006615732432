import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import DOMPurify from 'dompurify'
import CloseIcon from '../assets/icons/close_icon.svg'
import AlertIcon from '../assets/icons/alert_circle.svg'
import { Broadcast } from '../@types/notifications'

type TProps = {
  closePopover: () => void
  notifications: Broadcast[]
}

const NotificationsPopover: FC<TProps> = ({ closePopover, notifications }) => {
  const { t } = useTranslation()

  return (
    <div className="md:shadow-md">
      <div className="relative flex items-start justify-between p-2">
        <img
          src={AlertIcon}
          width={24}
          height={24}
          alt="alert"
          className="h-12 w-12"
        />
        <span className="absolute left-[20px] top-[21px] h-[23px] w-[23px] animate-ping rounded-full border-2 border-[#DC6803] opacity-50" />
        <button type="button" onClick={() => closePopover()}>
          <img
            src={CloseIcon}
            width={36}
            height={36}
            className="md:h-[24px] md:w-[24px]"
            alt="close button"
          />
        </button>
      </div>
      {notifications ? (
        <div className="px-4">
          {notifications.map((n) => (
            <div key={`notification-${n.id}`} className="mt-1 pb-4">
              <h5 className="font-bold">{n.title}</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(n.message),
                }}
              />
              {n.link && n.link.length > 0 ? (
                <a
                  rel="noreferrer"
                  href={n.link}
                  target="_blank"
                  className="mt-1 block font-bold text-bronze hover:brightness-110"
                >
                  {t('user.notifications.show_more')}
                </a>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default NotificationsPopover
