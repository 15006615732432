import { FC } from 'react'

type TDotProps = {
  widthHeight?: string
  position?: string
  bgColor?: string
}

const DotNotification: FC<TDotProps> = ({
  widthHeight = 'h-2.5 w-2.5',
  position = 'right-0 top-0',
  bgColor = 'bg-bronze',
}) => {
  return (
    <span className={`absolute flex ${position} ${widthHeight}`}>
      <span
        className={`absolute inline-flex h-full w-full animate-ping rounded-full opacity-75 ${bgColor}`}
      />
      <span
        className={`relative inline-flex rounded-full ${widthHeight} ${bgColor}`}
      />
    </span>
  )
}

export default DotNotification
