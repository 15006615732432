/* eslint-disable @typescript-eslint/no-explicit-any */

/* Generated by chatGPT :D */

type RawNode = {
  id: number
  id_parent: number
  [key: string]: any
}

type Node<T extends RawNode> = T & {
  id: number
  children?: Node<T>[]
}

type NodeMap<T extends RawNode> = {
  [id: number]: Node<T>
}

type Tree<T extends RawNode> = Node<T>[]

function arrayToTree<T extends RawNode>(
  arr: T[],
  rootId = 0,
  idKey: keyof T = 'id' as keyof T,
  parentIdKey: keyof T = 'id_parent' as keyof T
): Tree<T> {
  const map: NodeMap<T> = {}
  const roots: Node<T>[] = []

  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i]
    map[item[idKey]] = { ...item }
  }

  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i]
    const parentId = item[parentIdKey]
    const node = map[item[idKey]]

    if (!node) continue

    if (parentId !== rootId) {
      const parent = map[parentId]

      if (!parent) continue

      if (!parent.children) {
        parent.children = []
      }

      parent.children.push(node)
    } else {
      roots.push(node)
    }
  }

  return roots as Tree<T>
}

export default arrayToTree
