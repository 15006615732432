import { useMutation } from '@tanstack/react-query'
import { api, queryClient } from '../../index'
import { removeAllTokens } from '../../../utils/auth'

const useLogoutMutation = () =>
  useMutation({
    mutationFn: () => api().post('auth/logout').json<{ status: boolean }>(),
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [] })
      removeAllTokens()
    },
  })

export default useLogoutMutation
