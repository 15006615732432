import React, { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { XMarkIcon } from '@heroicons/react/24/solid'
import SearchIcon from '../../../assets/icons/search_icon_yellow.svg'

type TInputProps = {
  placeholder: string
  wrapperClassNames?: string
  classNames?: string
  initialValue?: string
  showClearButton?: boolean
  onSubmit?: (value: string) => void
  onChange?: (value: string) => void
}

const SearchInput: FC<TInputProps> = ({
  placeholder,
  wrapperClassNames = 'bg-white-lighter',
  classNames = 'bg-white-lighter',
  initialValue = '',
  showClearButton = false,
  onSubmit = undefined,
  onChange = undefined,
}) => {
  const [inputValue, setInputValue] = useState(initialValue)
  const handleKeyDownSubmit = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && inputValue.length > 0) {
      event.preventDefault()
      event.stopPropagation()
      if (typeof onSubmit === 'function') onSubmit(inputValue)
    }
  }

  const handleSubmit = () => {
    if (inputValue.length > 0 && typeof onSubmit === 'function')
      onSubmit(inputValue)
  }

  useEffect(() => {
    if (typeof onChange === 'function') {
      const timeOutId = setTimeout(() => {
        onChange(inputValue)
      }, 200)
      return () => clearTimeout(timeOutId)
    }
    return undefined
  }, [inputValue, onChange])

  return (
    <div
      className={`flex max-w-[490px] border border-transparent px-3 focus-within:border-bronze ${wrapperClassNames}`}
    >
      <button type="button" onClick={() => handleSubmit()}>
        <img
          src={SearchIcon}
          alt="Search input icon"
          className="cursor-pointer"
        />
      </button>
      <input
        type="text"
        name="searchInput"
        placeholder={placeholder}
        value={inputValue}
        className={`w-full border-0 focus:ring-0 ${classNames}`}
        onKeyDown={(event) => handleKeyDownSubmit(event)}
        onChange={(event) => setInputValue(event.target.value)}
      />
      {showClearButton ? (
        <button
          type="button"
          onClick={() => setInputValue(initialValue)}
          disabled={!inputValue.length}
          aria-label="Close"
        >
          <XMarkIcon
            className={clsx('h-5', {
              'text-black': inputValue.length,
              'text-grey-darker': !inputValue.length,
            })}
          />
        </button>
      ) : null}
    </div>
  )
}

export default SearchInput
