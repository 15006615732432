import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'

import { useCallback, useContext, useEffect } from 'react'
import data from './config'
import { CookieCategoryType } from '../../@types/cookies'
import Button from '../reusableComponents/Button'
import { CookieContext } from './CookieProvider'

function CookieBar() {
  const cookieContext = useContext(CookieContext)
  const { t, i18n } = useTranslation('global', { keyPrefix: 'cookies' })

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        cookieContext?.acknowledgeCookies()
        cookieContext?.showModal(false)
      }
    },
    [cookieContext]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])
  if (!data || !i18n.resolvedLanguage || cookieContext?.cookieSet) return null

  return (
    <div className="cookie-bar fixed bottom-0 left-0 z-10 w-full bg-white px-4 py-6 normal-case shadow-inner sm:top-auto sm:h-auto sm:px-16">
      <div className="float-right">
        <button
          type="button"
          onClick={() => cookieContext?.acknowledgeCookies()}
          className="text-gray"
        >
          X
        </button>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data[i18n.resolvedLanguage].consent),
        }}
      />

      <div className="mt-4 flex flex-col flex-wrap lg:flex-row lg:flex-nowrap">
        <div className="text-center">
          <Button
            onClick={() =>
              cookieContext?.acknowledgeCookies(
                data.categoryTypes
                  .filter((cT: CookieCategoryType) => cT.active)
                  .map((cT: CookieCategoryType) => cT.id)
              )
            }
            text={t('allowAll')}
            classNames="mr-2 mb-0"
          />
        </div>

        <div className="text-center">
          <Button
            classNames="mb-0"
            onClick={() => cookieContext?.acknowledgeCookies()}
            text={t('disallowAll')}
          />
        </div>

        <div className="lg:align-right text-center lg:grow">
          <Button
            classNames="lg:float-right"
            onClick={() => cookieContext?.showModal(true)}
            text={t('setup')}
          />
        </div>
      </div>
    </div>
  )
}

export default CookieBar
